import React from 'react'
import {
  Row, Col, Container
} from "reactstrap";

import annualreport2023 from '../../assets/images/annualreport2023-p-1080.png';
import annualreport2022 from '../../assets/images/annualreport2022-p-1080.png';
import brilliance from '../../assets/images/brilliance.png';
import paths from '../../assets/images/paths.png';
import ar2018 from '../../assets/images/ar2018.png';
import fcl2017 from '../../assets/images/fcl2017.png';
import fcl25years from '../../assets/images/fcl25years.png';
import fcl2015 from '../../assets/images/fcl2015.png';
import fcl2013 from '../../assets/images/fcl2013.png';
const Annualreports = () => {
  return (
    <Container>
      <Row className='justify-content-center'>
        <Col
          sm="12"
          md={{
            size: 10
          }}
        >
          <h1 className="mt-4 mb-4 fs-1 fw-bold">Annual Reports</h1>
        </Col>
        <Col
          sm="12"
          md={{
            size: 10
          }}
          className='text-center'
        >
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" loading="lazy" src={annualreport2023} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" />
            </div>
            <h2 className="mt-3">Beauty Is What We Do</h2>
            <p className="w-dyn-bind-empty"></p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/Full-Circle-Learning-Annual-Report-2023.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" loading="lazy" src={annualreport2022} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" />
            </div>
            <h2 className="mt-3">Nurturing a New Generation of Humanitarians</h2>
            <p className="w-dyn-bind-empty"></p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/Full-Circle-Learning-Annual-Report-2022.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center"><img alt="A group of kids around a light" loading="lazy" src={paths} />
            </div>
            <h2 className="mt-3">Paths of Purpose</h2>
            <p className="w-dyn-bind-empty"></p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/paths_of_purpose.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" loading="lazy" src={brilliance} />
            </div>
            <h2 className="mt-3">From Resilience to Brilliance</h2>
            <p className="w-dyn-bind-empty"></p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/Full-CircleLearning-2020-2021-From-Resilience-to-Brilliance.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" className="img_annual_report" loading="lazy" src={ar2018} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" style={{ width:"100%" }} />
            </div>
            <h2 className="mt-3">Full-Circle Learning Annual Report 2018</h2>
            <p>Catch the highlights of 2018!</p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/annual-reports/annual-report-2017-2018.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" className="img_annual_report" loading="lazy" src={fcl2017} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" style={{ width:"100%" }} />
            </div>
            <h2 className="mt-3">Full-Circle Learning Calendar 2017</h2>
            <p className="w-dyn-bind-empty"></p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/annual-reports/learning-calendar-2017.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" className="img_annual_report" loading="lazy" src={fcl25years} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" style={{ width:"100%" }}/>
            </div>
            <h2 className="mt-3">Full-Circle Learning: 25 Years of Transforming Lives</h2>
            <p>Catch the highlights of the year - and of the past quarter century!</p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/annual-reports/fcl-25-years.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" className="img_annual_report" loading="lazy" src={fcl2015} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" style={{ width:"100%" }}/>
            </div>
            <h2 className="mt-3">Full-Circle Learning Annual Report 2013 - 2015</h2>
            <p className="w-dyn-bind-empty"></p>
            <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/annual-reports/annual-report-2013-2015.pdf" className="mx-auto download-button">Download</a>
          </div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img alt="A group of kids around a light" className="img_annual_report" loading="lazy" src={fcl2013} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 940px" style={{ width:"100%" }}/>
            </div>
            <h2 className="mt-3">Full-Circle Learning Annual Report 2012-2013</h2>
            <p className="w-dyn-bind-empty"></p>
            <a target="_blank" rel="noreferrer"  href="https://fullcirclelearningassets.s3.amazonaws.com/annual-reports/annual-report-2012-2013.pdf" className="mx-auto download-button">Download</a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Annualreports
