import React from 'react'
import {
    Row, Col, Card, Container
} from "reactstrap";
const CommunityImpact = () => {
    return (
        <Container>
            <Row>
                <Col
                    sm="12"
                    md={{
                        offset: 1,
                        size: 10
                    }}
                >
                    <Card>
                        <h1>Community Impact</h1>
                        <a target="_blank" rel="noreferrer" href="https://fullcirclelearningassets.s3.amazonaws.com/full-circle-learning-annual-report-june-2023.pdf" className='inquires'>2023 Annual Report</a>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default CommunityImpact